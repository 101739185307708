<template>
  <v-row>
    <!-- toolbar -->
    <v-col cols="12">
      <v-toolbar dense flat color="primary" dark rounded>
        <v-toolbar-title class="headline font-weight-medium">{{
          toolbarTitle
        }}</v-toolbar-title>
        <v-spacer />
        <v-text-field
          class="toolbarSearch"
          rounded
          label="Search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
          clearable
        ></v-text-field>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            target="_blank"
            depressed
            color="primary"
            title="Export MPS Excel "
            @click="exportToExcel(items)"
          >
            Export MPS
          </v-btn>
          <!-- mps external one drive link -->
          <v-btn
            href="https://onedrive.live.com/edit.aspx?resid=B3227A520DC366E4!2874"
            target="_blank"
            depressed
            color="primary"
            title="Web Based MPS Excel Spreadsheet"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <!-- mps external one drive link -->
          <v-btn
            depressed
            color="primary"
            @click="addNewRecord"
            title="Add New MPS Record"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
          <v-btn depressed color="primary" to="/" title="Go Home">
            <v-icon>mdi-home-outline</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-col>
    <!-- table -->
    <v-col cols="12">
      <v-card outlined>
        <v-data-table
          :loading="loading"
          dense
          :headers="[
            { text: 'Traveler', value: 'traveler' },
            { text: 'Package Code', value: 'packageCode' },
            { text: 'Package Destination', value: 'packageDestination' },
            { text: 'PUID', value: 'puid' },
            { text: 'Issue Date', value: 'issueDate' },
            { text: 'Travel Date', value: 'travelDate' },
            { text: 'Sponsor/Org', value: 'sponsorOrganization' },
          ]"
          :items="items"
          :items-per-page="-1"
          @click:row="edit"
          :search="search"
          hide-default-footer
          sort-by="daysAgo"
          :item-class="itemClass"
        ></v-data-table>
      </v-card>
    </v-col>
    <!-- full screen edit/add dialog -->
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <!-- dialog toolbar -->
        <v-toolbar
          dark
          color="primary"
          dense
          flat
          style="position: sticky; top: 0; width: 100%; z-index: 500"
        >
          <v-toolbar-title>
            <strong> {{ editing ? "Edit" : "Add New MPS Record" }} </strong>
            <span class="ml-6"> {{ item.traveler }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              depressed
              color="primary"
              dark
              :loading="loading"
              :disabled="loading"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- dialog content -->
        <v-card-text class="text--primary mt-6">
          <!-- form -->
          <v-form ref="form" @submit.prevent="save">
            <v-row>
              <!-- traveler -->
              <v-col cols="6">
                <v-text-field
                  v-model="item.traveler"
                  :loading="loading"
                  :disabled="loading"
                  label="Traveler"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- priorReservations -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.priorReservations"
                  :loading="loading"
                  :disabled="loading"
                  label="Prior Reservations"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- registeredBookingStatus -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.registeredBookingStatus"
                  :loading="loading"
                  :disabled="loading"
                  label="Registered Booking Status"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- expectedTotalCost -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.expectedTotalCost"
                  :loading="loading"
                  :disabled="loading"
                  label="Expected Total Cost"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- expectedUpgradeIncome -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.expectedUpgradeIncome"
                  :loading="loading"
                  :disabled="loading"
                  label="Expected Upgrade Income"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- expectedNetOutflow -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.expectedNetOutflow"
                  :loading="loading"
                  :disabled="loading"
                  label="Expected Net Outflow"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- packageCode -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.packageCode"
                  :loading="loading"
                  :disabled="loading"
                  label="Package Code"
                  hide-details="auto"
                  v-if="editing"
                ></v-text-field>
                <v-autocomplete
                  v-else
                  label="Package Code"
                  :items="retailPackages"
                  item-text="packageCode"
                  hide-details="auto"
                  :loading="loading"
                  :disabled="loading"
                  @change="populatePackageInfo"
                  return-object
                  single-line
                  id="packageCodeAutocomplete"
                >
                  <template v-slot:item="data">{{
                    data.item.packageCode.toUpperCase()
                  }}</template>

                  <template v-slot:selection="data">{{
                    data.item.packageCode.toUpperCase()
                  }}</template>
                </v-autocomplete>
              </v-col>
              <!-- packageDestination -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.packageDestination"
                  :loading="loading"
                  :disabled="loading"
                  label="Package Destination"
                  :rules="[(v) => !!v || 'Field is required']"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- sponsorOrganization -->
              <v-col cols="6">
                <v-text-field
                  v-model="item.sponsorOrganization"
                  :loading="loading"
                  :disabled="loading"
                  label="Sponsor Organization"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- rep -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.rep"
                  :loading="loading"
                  :disabled="loading"
                  label="Rep"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- congratsSent -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.congratsSent"
                  :loading="loading"
                  :disabled="loading"
                  label="Congrats Sent"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- issueDate -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.issueDate"
                  :loading="loading"
                  :disabled="loading"
                  label="Issue Date"
                  hide-details="auto"
                  @input="populateOriginalExpiryDate"
                ></v-text-field>
              </v-col>
              <!-- originalExpiryDate -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.originalExpiryDate"
                  :loading="loading"
                  :disabled="loading"
                  label="Original Expiry Date"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- amendedExpiryDate -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.amendedExpiryDate"
                  :loading="loading"
                  :disabled="loading"
                  label="Amended Expiry Date"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- maxExtensionLastTravelDateOneYearAfterLastArrivalDate -->
              <v-col cols="3">
                <v-text-field
                  v-model="
                    item.maxExtensionLastTravelDateOneYearAfterLastArrivalDate
                  "
                  :loading="loading"
                  :disabled="loading"
                  label="Max Extension / Last Travel Date / 1 Year After Last Arrival Date"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- puid -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.puid"
                  :loading="loading"
                  :disabled="loading"
                  label="PUID"
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </v-col>
              <!-- price -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.price"
                  :loading="loading"
                  :disabled="loading"
                  label="Price"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- openAccount -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.openAccount"
                  :loading="loading"
                  :disabled="loading"
                  label="Open Account"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- commPaid -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.commPaid"
                  :loading="loading"
                  :disabled="loading"
                  label="Comm Paid"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- netSale -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.netSale"
                  :loading="loading"
                  :disabled="loading"
                  label="Net Sale"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- dateBooked -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.dateBooked"
                  :loading="loading"
                  :disabled="loading"
                  label="Date Booked"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- travelDate -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.travelDate"
                  :loading="loading"
                  :disabled="loading"
                  label="Travel Date"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- daysUntilTravel -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.daysUntilTravel"
                  :loading="loading"
                  :disabled="loading"
                  label="Days Until Travel"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- destination -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.destination"
                  :loading="loading"
                  :disabled="loading"
                  label="Destination"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- resort -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.resort"
                  :loading="loading"
                  :disabled="loading"
                  label="Resort"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- roomDepositPaid -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.roomDepositPaid"
                  :loading="loading"
                  :disabled="loading"
                  label="Room Deposit Paid"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- roomBalanceDue -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.roomBalanceDue"
                  :loading="loading"
                  :disabled="loading"
                  label="Room Balance Due"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- toursAndInclusionsPaid -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.toursAndInclusionsPaid"
                  :loading="loading"
                  :disabled="loading"
                  label="Tours And Inclusions Paid"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- toursAndInclusionsDue -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.toursAndInclusionsDue"
                  :loading="loading"
                  :disabled="loading"
                  label="Tours And Inclusions Due"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- totalRoomCost -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.totalRoomCost"
                  :loading="loading"
                  :disabled="loading"
                  label="Total Room Cost"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- toursAndInclusionsTotalCost -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.toursAndInclusionsTotalCost"
                  :loading="loading"
                  :disabled="loading"
                  label="Tours And Inclusions Total Cost"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- totalPackageCost -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.totalPackageCost"
                  :loading="loading"
                  :disabled="loading"
                  label="Total Package Cost"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- upgrade -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.upgrade"
                  :loading="loading"
                  :disabled="loading"
                  label="Upgrade"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- pl -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.pl"
                  :loading="loading"
                  :disabled="loading"
                  label="P/L"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- repeatPl -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.repeatPl"
                  :loading="loading"
                  :disabled="loading"
                  label="Repeat P/L"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- supplier -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.supplier"
                  :loading="loading"
                  :disabled="loading"
                  label="Supplier"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- confirmationNumber -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.confirmationNumber"
                  :loading="loading"
                  :disabled="loading"
                  label="confirmation #"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- previousBookingPaid -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.previousBookingPaid"
                  :loading="loading"
                  :disabled="loading"
                  label="Previous Booking Paid"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- previousBookingRefundedCredit -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.previousBookingRefundedCredit"
                  :loading="loading"
                  :disabled="loading"
                  label="Previous Booking Refunded Credit"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- previousUpgradePaid -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.previousUpgradePaid"
                  :loading="loading"
                  :disabled="loading"
                  label="Previous Upgrade Paid"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- plAfterRebooking -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.plAfterRebooking"
                  :loading="loading"
                  :disabled="loading"
                  label="P/L After Rebooking"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- previousBookingUpgrade -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.previousBookingUpgrade"
                  :loading="loading"
                  :disabled="loading"
                  label="Previous Booking Upgrade"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- adminNotes -->
              <v-col cols="6">
                <v-textarea
                  v-model="item.adminNotes"
                  :loading="loading"
                  :disabled="loading"
                  label="Admin Notes"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
              <!-- dateCreated -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.dateCreated"
                  :loading="loading"
                  :disabled="loading"
                  label="Date Created"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <!-- dateEdited -->
              <v-col cols="3">
                <v-text-field
                  v-model="item.dateEdited"
                  :loading="loading"
                  :disabled="loading"
                  label="Date Edited"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="sticky-v-card-actions">
          <v-btn
            color="error"
            text
            @click="deleteDialog = true"
            :loading="loading"
            :disabled="loading"
            v-if="editing"
            >Delete</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            :disabled="loading"
            @click="save"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Delete Record? </v-card-title>
        <v-card-text class="text--primary">
          This action is permanent and will remove this record from the
          database.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteDialog = false" text>Cancel</v-btn>
          <v-spacer />
          <v-btn color="error" text @click="deleteItem"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { portalsApp } from "@/firebase";
import moment from "moment";
import { utils, writeFile } from "xlsx";
export default {
  firestore: {
    retailPackages: portalsApp.firestore().collection("retailPackages"),
  },
  async mounted() {
    // bind to mps items
    let mps = await this.$bind(
      "items",
      portalsApp.firestore().collection("mps")
    );
    // adding a reliable sort for issueDate
    mps.forEach((i) => {
      let today = moment().startOf("day");
      let issueDate = moment(i.issueDate, "MM-DD-YYYY");
      let daysAgo = today.diff(issueDate, "days");
      i.daysAgo = daysAgo;
    });

    this.loading = false;

    // checking for url query params for direct links to MPS records
    if (Object.keys(this.$route.query).length === 0) return;
    if (this.$route.query.q) {
      let item = {
        id: this.$route.query.q,
      };
      this.edit(item);
    }
  },
  data: () => ({
    toolbarTitle: "MPS",
    loading: true,
    items: [],
    dialog: false,
    item: "",
    editing: false,
    search: "",
    deleteDialog: false,
    retailPackages: [],
  }),
  methods: {
    exportToExcel(objectData) {
      let colomn = [
        "A1",
        "B1",
        "C1",
        "D1",
        "E1",
        "F1",
        "G1",
        "H1",
        "I1",
        "J1",
        "K1",
        "L1",
        "M1",
        "N1",
        "O1",
        "P1",
        "Q1",
        "R1",
        "S1",
        "T1",
        "U1",
        "V1",
        "W1",
        "X1",
        "Y1",
        "Z1",
        "AA1",
        "AB1",
        "AC1",
        "AD1",
        "AE1",
        "AF1",
        "AG1",
        "AH1",
        "AI1",
        "AJ1",
        "AK1",
        "AL1",
        "AM1",
        "AN1",
        "AO1",
        "AP1",
      ];

      let mpsRecords = objectData.sort((a, b) => a.daysAgo - b.daysAgo);

      mpsRecords = mpsRecords.map((el) => {
        return {
          traveler: el.traveler || "",
          priorReservations: el.priorReservations || "",
          registeredBookingStatus: el.registeredBookingStatus || "",
          expectedTotalCost: el.expectedTotalCost || "",
          expectedUpgradeIncome: el.expectedUpgradeIncome || "",
          expectedNetOutflow: el.expectedNetOutflow || "",
          packageCode: el.packageCode || "",
          packageDestination: el.packageDestination || "",
          sponsorOrganization: el.sponsorOrganization || "",
          rep: el.rep || "",
          congratsSent: el.congratsSent || "",
          issueDate: el.issueDate || "",
          originalExpiryDate: el.originalExpiryDate || "",
          amendedExpiryDate: el.amendedExpiryDate || "",
          maxExtensionLastTravelDateOneYearAfterLastArrivalDate:
            el.maxExtensionLastTravelDateOneYearAfterLastArrivalDate || "",
          puid: el.puid || "",
          price: el.price || "",
          openAccount: el.openAccount || "",
          commPaid: el.commPaid || "",
          netSale: el.netSale || "",
          dateBooked: el.dateBooked || "",
          travelDate: el.travelDate || "",
          destination: el.destination || "",
          resort: el.resort || "",
          roomDepositPaid: el.roomDepositPaid || "",
          roomBalanceDue: el.roomBalanceDue || "",
          toursAndInclusionsPaid: el.toursAndInclusionsPaid || "",
          toursAndInclusionsDue: el.toursAndInclusionsDue || "",
          totalRoomCost: el.totalRoomCost || "",
          toursAndInclusionsTotalCost: el.toursAndInclusionsTotalCost || "",
          totalPackageCost: el.totalPackageCost || "",
          upgrade: el.upgrade || "",
          pl: el.pl || "",
          repeatPl: el.repeatPl || "",
          supplier: el.supplier || "",
          confirmationNumber: el.confirmationNumber || "",
          previousBookingPaid: el.previousBookingPaid || "",
          previousBookingRefundedCredit: el.previousBookingRefundedCredit || "",
          previousUpgradePaid: el.previousUpgradePaid || "",
          plAfterRebooking: el.plAfterRebooking || "",
          previousBookingUpgrade: el.previousBookingUpgrade || "",
          adminNotes: el.adminNotes || "",
        };
      });
      const worksheet = utils.json_to_sheet(mpsRecords);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      colomn.map((el) => {
        workbook.Sheets.Sheet1[el].v =
          workbook.Sheets.Sheet1[el].v.charAt(0).toUpperCase() +
          workbook.Sheets.Sheet1[el].v
            .slice(1)
            .replace(/([A-Z])/g, " $1")
            .trim();
      });
      writeFile(workbook, "mps.xlsx");
    },

    itemClass(item) {
      if (!item) return "";
      // starting with maxExtension date && falling back to originalExpiryDate
      if (item.maxExtensionLastTravelDateOneYearAfterLastArrivalDate) {
        let maxExtension = moment(
          item.maxExtensionLastTravelDateOneYearAfterLastArrivalDate,
          "M/D/YYYY"
        );
        if (!maxExtension._isAMomentObject) return "";
        let today = moment().startOf("day");
        let isExpired = maxExtension.isBefore(today);
        if (isExpired) return "red lighten-4";
      } else {
        let originalExpiryDate = moment(item.originalExpiryDate, "M/D/YYYY");
        if (!originalExpiryDate._isAMomentObject) return "";
        let today = moment().startOf("day");
        let isExpired = originalExpiryDate.isBefore(today);
        if (isExpired) return "red lighten-4";
      }
    },
    addNewRecord() {
      this.editing = false;
      this.dialog = true;
      this.item = {
        dateCreated: moment().format("YYYY-MM-DD"),
        destination: "",
        packageDestination: "",
      };
    },
    populatePackageInfo(e) {
      if (!e) return;
      this.item.packageDestination = e.destination;
    },
    populateOriginalExpiryDate(e) {
      if (!e) return;
      if (e.length >= 6) {
        let date = e.replaceAll(" ", "");
        date = moment(date);
        if (date._d == "Invalid Date") return;
        let expDate = date.clone().add(3, "years");
        if (expDate._isAMomentObject)
          expDate = moment(expDate).format("MM/DD/YYYY");
        this.item.originalExpiryDate = expDate;
      } else {
        this.item.originalExpiryDate = "";
      }
    },
    // fetching item again to create a "fresh" copy to edit that will not effect items in table if user cancels the edit
    async edit(item) {
      if (!item) return;
      this.loading = true;
      let doc = portalsApp.firestore().collection("mps").doc(item.id);
      doc = await doc.get();
      let i = doc.data();

      // calculating daysUntilTravel
      let today = moment().startOf("day");
      if (i.travelDate && i.travelDate != "" && i.travelDate != undefined) {
        let travelDate = moment(i.travelDate, "MM-DD-YYYY");
        if (travelDate._isAMomentObject) {
          let daysUntilTravel = travelDate.diff(today, "days", true);
          // daysUntilTravel = daysUntilTravel.toFixed();
          if (daysUntilTravel >= 0) {
            i.daysUntilTravel = daysUntilTravel;
          } else {
            i.daysUntilTravel = "Already Traveled";
          }
        }
      }

      this.item = i;
      this.editing = true;
      this.dialog = true;
      this.loading = false;
    },
    async save() {
      if (this.editing) {
        this.loading = true;
        this.item.dateEdited = new Date().toISOString().substring(0, 10);
        let doc = portalsApp.firestore().collection("mps").doc(this.item.id);
        await doc.update(this.item);
        this.loading = false;
        this.dialog = false;
        this.item = "";
        // success snackbar
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "MPS record updated successfully",
          timeout: 5000,
        });
      } else {
        if (!this.$refs.form.validate())
          return this.$toast.error("Please check form for errors", {
            timeout: 3000,
          });
        this.loading = true;
        await portalsApp.firestore().collection("mps").add(this.item);
        this.loading = false;
        this.dialog = false;
        this.item = "";
        // success snackbar
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "MPS record created successfully",
          timeout: 5000,
        });
      }
    },
    async deleteItem() {
      if (!this.editing) return;
      this.loading = true;
      let doc = portalsApp.firestore().collection("mps").doc(this.item.id);
      await doc.delete();
      this.loading = false;
      this.deleteDialog = false;
      this.dialog = false;
      this.item = "";
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
}
</style>
